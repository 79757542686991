.event-card-lg {
  width: 90% !important;
  position: relative;
}
.event-card-lg .event-container {
  background-color: #fbf7ec;
  height: 310px;
  width: 95%;
  margin: 0 0 50px;
}

.event-card-lg .date-container {
  width: 95%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.event-card-lg .date-container .date-item {
  width: 25%;
  background-color: #eeeded;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  padding: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}

@media (max-width: 1341px) {
  .event-card-lg .date-container {
    font-size: 14px;
  }
}

.event-card-lg .event-name {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}
.event-card-lg .event-container .event-content-container {
  position: relative;
  width: 100%;
}
.event-card-lg .event-container .banner-container {
  margin: 0;
  width: 303px !important;
  height: 310px !important;
}
.event-card-lg .event-container .banner-container > img {
  width: 100%;
  height: 100%;
}
.event-card-lg .event-container .event-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #707070;
}
.event-card-lg .event-container .lastSeatsAlert {
  color: #f30e0e;
}
.event-card-lg .event-container .guru-details {
  position: absolute;
  top: 60%;
  left: -60px;
  transform: translateY(-60%);
  width: 100%;
}
.event-card-lg .event-container .avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 20px solid #fbf7ec;
}
.event-card-lg .event-container .divider {
  width: 100%;
  height: 1px;
  background-color: #cfa79d;
}
.event-status {
  position: absolute;
  right: 40px;
  top: 0;
  padding-top: 5px;
}

.event-card-lg .completed {
  color: red;
  font-weight: bold;
  position: absolute;
  top: 30px;
  right: 0;
}
